import React, { useState } from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import { getSearchParams } from "gatsby-query-params"
import axios from "axios"
import TextField from "@material-ui/core/TextField"
import Slide from "@material-ui/core/Slide"
import Snackbar from "@material-ui/core/Snackbar"
import Alert, { Color } from "@material-ui/lab/Alert"

const styles = createStyles({
  contactForm: {
    width: "100%",
  },
  inputContainer: {
    background: "#F1F1F1",
    padding: 25,
    paddingTop: 4,
    paddingBottom: 16,
    borderRadius: 25,
    marginBottom: 20,
  },
  callToAction: {
    paddingTop: 20,
    marginBottom: 60,
    "@media (max-width: 599.95px)": {
      padding: 0,
      marginBottom: 20,
    },
  },
  button: {
    position: "relative",
    fontWeight: 600,
    fontSize: "0.83rem",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:
      "linear-gradient(270deg, #FFBE01 0%, #FFB401 32.29%, #FF9401 65.62%, #FF7D01 100%)",
    height: 44,
    borderRadius: 30,
    padding: "18.5px 50px 12px 50px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    textDecoration: "none",
    color: "#212121",
    whiteSpace: "nowrap",

    "&:disabled": {
      filter: "grayscale(1)",
      cursor: "not-allowed",
    },
  },
})

export interface Props {}

type PropsAndStyles = Props & WithStyles<typeof styles>

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& textarea": {
      paddingTop: 10,
    },
    "& label": {
      textAlign: "left",
    },
    "& label.Mui-focused": {
      color: "#898989",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#898989",
      },
      "&:hover fieldset": {
        borderColor: "#898989",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#898989",
      },
    },
  },
})(TextField)

function Transition(props) {
  return <Slide {...props} direction="left" />
}

const ContactForm = ({ classes }: PropsAndStyles) => {
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState<Color>("success")
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const { position } = getSearchParams()
  const subject = position ? `${position} - Application` : "Form Submission"

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)

    axios
      .post(
        "https://api.voltgames.io/.netlify/functions/server",
        { firstName, lastName, email, subject, message }
      )
      .then(res => {
        setLoading(false)
        setFirstName("")
        setLastName("")
        setEmail("")
        setMessage("")

        setSnackbarOpen(true)
        setSnackbarSeverity("success")
        setSnackbarMessage("Message Sent!")
      })
      .catch(err => {
        setLoading(false)
        setSnackbarOpen(true)
        setSnackbarSeverity("error")
        setSnackbarMessage(err.toString())
      })
  }

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    setSnackbarOpen(false)
  }

  return (
    <form className={classes.contactForm} onSubmit={handleSubmit}>
      <div className={classes.inputContainer}>
        <CssTextField
          id="firstName"
          label="First Name"
          name="first name"
          placeholder="Your first name"
          type="text"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          disabled={loading}
          required
        />
      </div>
      <div className={classes.inputContainer}>
        <CssTextField
          id="lastName"
          label="Last Name"
          name="last name"
          placeholder="Your last name"
          type="text"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          disabled={loading}
          required
        />
      </div>
      {/* <div className={classes.inputContainer}>
      <CssTextField
        id="phoneNumber"
        label="Phone Number"
        name="phone number"
        placeholder="Your phone number"
        type="tel"
      />
    </div> */}
      <div className={classes.inputContainer}>
        <CssTextField
          id="email"
          label="Email Address"
          name="email address"
          placeholder="Your email address"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          disabled={loading}
          required
        />
      </div>
      <div className={classes.inputContainer}>
        <CssTextField
          id="message"
          label="Tell us a little more about what you’re looking for (publishing, collaboration, game feedback, etc.)"
          name="message"
          placeholder="Type here"
          type="text"
          multiline
          rows={12}
          value={message}
          onChange={e => setMessage(e.target.value)}
          disabled={loading}
          required
        />
      </div>

      <div className={classes.callToAction}>
        <button className={classes.button} type="submit" disabled={loading}>
          Send Message
        </button>
      </div>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={Transition}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </form>
  )
}

export default withStyles(styles)(ContactForm)
