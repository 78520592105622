import React from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"
import Socials from "../components/socials"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Heading from "../components/heading"
import ContactForm from "../components/contact-form"
import Featured from "../components/featured"
import FeaturedDetails from "../components/featured-details"
import SEO from "../components/seo"

const styles = createStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 700,
    padding: 50,
    margin: "auto",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.56rem",
    textAlign: "center",
    color: "#212121",
    "@media (max-width: 599.95px)": {
      padding: "40px 20px",
    },
  },
  info: {
    marginTop: 60,
    marginBottom: 20,
    fontSize: "1.11rem",
    lineHeight: "1.44rem",
    alignItems: "center",
    textAlign: "center",
    color: "#898989",
    whiteSpace: "break-spaces",
  },
  coloredText: {
    color: "#FF7900",
  },
  mail: {
    marginLeft: 5,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
})

type PropsAndStyles = WithStyles<typeof styles>

const ContactPage = ({ classes }: PropsAndStyles) => {
  return (
    <Layout>
      <Hero
        imagePath="hero/contact.jpg"
        title="Get"
        coloredTitle="in touch."
        description="Feel free to send us a message, we love to talk!"
      />

      <div className={classes.content}>
        <Heading
          title="Drop us a line"
          coloredWords={1}
          // description="Are you looking to collaborate with us?"
        />

        <div className={classes.info}>
          Fill out our fom below or send us an email:
          <a
            className={classNames(classes.coloredText, classes.mail)}
            href="mailto:info@voltgames.io"
          >
            info@voltgames.io
          </a>
        </div>
        <ContactForm />
      </div>

      <Featured imagePath="featured/contact.jpg" large>
        <FeaturedDetails
          title="Find Us!"
          description="Visit us in our Social Media Channels"
          callToAction={<Socials color="#FFFFFF" size={45} />}
        />
      </Featured>

      <SEO title="Contact" />
    </Layout>
  )
}

export default withStyles(styles)(ContactPage)
